<template>
<div class="animated fadeIn">
    <b-card class="card-border mt-4">
        <b-card-title><i class="fa fa-users"></i> Disposal Management</b-card-title>
        <b-card-sub-title>Handles the management of assets set for disposal</b-card-sub-title>
        <div fluid class="px-2 mt-4">
            <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

            <!-- Filter  -->
            <b-row class="mt-2">
                <b-col sm="12" md="3" lg="3">
                    <b-button v-b-popover.hover.right="'Toggle to show/hide filter options'" v-b-toggle.collapse-1 class="filter">
                        FILTER OPTIONS
                    </b-button>
                </b-col>
                <b-col sm="12">
                    <!-- Collapsible Filter Options -->
                    <b-collapse id="collapse-1" class="mt-2">
                        <b-card>
                            <b-row no-gutters>
                                <b-col lg="4" md="6" sm="12" class="mr-4">
                                    <b-form-group label="Date From">
                                        <b-form-datepicker v-model="filterBy.dateFrom" locale="en" reset-button label-reset-button="Clear" :date-format-options="{
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                          weekday: 'short',
                        }" :date-disabled-fn="dateFromDisabled" />
                                    </b-form-group>
                                </b-col>
                                <b-col lg="4" md="6" sm="12" class="mr-4">
                                    <b-form-group label="Date To">
                                        <b-form-datepicker v-model="filterBy.dateTo" locale="en" reset-button label-reset-button="Clear" :date-format-options="{
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                          weekday: 'short',
                        }" />
                                    </b-form-group>
                                </b-col>
                                <b-col lg="4" md="6" sm="12" class="mr-4">
                                    <b-form-group label="Status">
                                        <v-select class="style-chooser" label="text" placeholder=" - Please select - " :options="filterByOptions.disposalStatusOptions" :reduce="(status) => status.value" v-model="filterBy.status">
                                            <template v-slot:no-options="{ search, searching }">
                                                <template v-if="searching">
                                                    No results found for
                                                    <em>
                                                        <strong>{{ search }}</strong>
                                                    </em>
                                                </template>
                                                <em :style="{ opacity: 0.5 }" v-else>
                                                    Start typing to search status
                                                </em>
                                            </template>
                                        </v-select>
                                    </b-form-group>
                                </b-col>
                                <b-col lg="4" md="5" sm="12" class="mr-4">
                                    <b-form-group label="Asset Type">
                                        <v-select class="style-chooser" label="text" placeholder=" - Please select - " :options="filterByOptions.assetTypes" :reduce="(assetType) => assetType.value" v-model="filterBy.assetType">
                                            <template v-slot:no-options="{ search, searching }">
                                                <template v-if="searching">
                                                    No results found for
                                                    <em>
                                                        <strong>{{ search }}</strong>
                                                    </em>
                                                </template>
                                                <em :style="{ opacity: 0.5 }" v-else>
                                                    Start typing to search for a company
                                                </em>
                                            </template>
                                        </v-select>
                                    </b-form-group>
                                </b-col>
                                <b-col lg="4" md="5" sm="12" class="mr-4">
                                    <b-form-group label="Asset Code">
                                        <b-form-input name="Asset Code" type="search" class="numFont" v-model="filterBy.assetCode" placeholder />
                                    </b-form-group>
                                </b-col>
                                <b-col lg="4" md="5" sm="12" class="mr-4">
                                    <b-form-group label="Disposal ID" description="NOTE: Input the exact Disposal ID to search">
                                        <b-form-input name="Disposal ID" type="search" class="numFont" v-model="filterBy.disposalRequestId" placeholder="DRXXXXXXXXXXXXX" />
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row no-gutters>
                                <b-col sm="12">
                                    <b-button class="mr-1" variant="success" @click="onFilterRequest">
                                        Search
                                    </b-button>
                                    <b-button class="mr-1" variant="primary" @click="resetFilters">
                                        Reset
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-collapse>
                </b-col>
            </b-row>

            <!-- Select Actions and Items Per Page Options -->
            <b-row>
                <b-col sm="6" md="3" class="mt-4 mb-2">
                    <b-dropdown text=" Select Actions " variant="dark" slot="append">
                        <b-dropdown-item v-b-modal.add-disposal v-show="!isViewer">
                            Add Disposal Request
                        </b-dropdown-item>
                        <b-dropdown-item>
                            <json-excel :data="exportData" :fields="exportFields" type="xls" :name="fileName + '.xls'">
                                Export Disposal Request in Excel
                            </json-excel>
                        </b-dropdown-item>
                        <b-dropdown-item>
                            <json-excel :data="exportData" :fields="exportFields" type="csv" :name="fileName + '.csv'">
                                Export Disposal Request to CSV
                            </json-excel>
                        </b-dropdown-item>
                    </b-dropdown>
                </b-col>
                <b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
                    <b-input-group prepend="Show" append="/ Page">
                        <b-form-select :options="pageOptions" v-model="perPage" />
                    </b-input-group>
                </b-col>
            </b-row>

            <b-table ref="disposalTable" show-empty striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" responsive>
                <template v-slot:cell(actions)="row">
                    <DisposalRowActions :row="row" :isViewer="isViewer" />
                </template>

                <template v-slot:cell(dateCreated)="row">
                        {{ showFormattedDateTime(row.item.dateCreated) }}
                    </template>

                <template v-slot:cell(status)="row">
                    <DisposalRowStatus :row="row" />
                </template>

                <template slot="row-details" slot-scope="row">
                    <DisposalViewDetails :row="row" />
                </template>
            </b-table>

            <b-row>
                <b-col md="8" sm="12" class="my-1">
                    <span class="total-display">Total: {{ items.length }}</span>
                </b-col>
                <b-col md="4" sm="12" class="my-1">
                    <b-pagination align="right" :total-rows="items.length" :per-page="perPage" v-model="currentPage" class="my-0" />
                </b-col>
            </b-row>
        </div>
    </b-card>

    <!-- Modals here -->
    <AddDisposal :assetTypeOptions="filterByOptions.assetTypes" :approverOptions="approverOptions" :loggedUser="loggedUser" />
    <EditDisposal :assetTypeOptions="filterByOptions.assetTypes" :approverOptions="approverOptions" :loggedUser="loggedUser" />
    <CancelDisposal :assetTypeOptions="filterByOptions.assetTypes" :approverOptions="approverOptions" :loggedUser="loggedUser" />
    <SubmitDisposal :assetTypeOptions="filterByOptions.assetTypes" :approverOptions="approverOptions" :loggedUser="loggedUser" />
</div>
</template>

<script>
// Component
import AddDisposal from "@/views/maintenance/disposal/AddDisposal";
import EditDisposal from "@/views/maintenance/disposal/EditDisposal";
import CancelDisposal from "@/views/maintenance/disposal/CancelDisposal";
import SubmitDisposal from "@/views/maintenance/disposal/SubmitDisposal";
import DisposalRowActions from "@/views/maintenance/disposal/DisposalRowActions";
import DisposalRowStatus from "@/views/maintenance/disposal/DisposalRowStatus";
import DisposalViewDetails from "@/views/maintenance/disposal/DisposalViewDetails";

// Util
import {
    DateUtil
} from "@/utils/dateutil";
import {
    ValidationUtil
} from "@/utils/validationUtil";
import {
    DropDownItemsUtil
} from "@/utils/dropDownItemsUtil";

// API
import userApi from "@/api/userApi";
import disposalRequestApi from "@/api/disposalRequestApi";

// Others
import config from "@/config/env-constants";
import EventBus from "@/shared/event-bus";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import JsonExcel from "vue-json-excel";
import _ from "lodash";

export default {
    name: "disposal",
    components: {
        Loading,
        JsonExcel,
        AddDisposal,
        EditDisposal,
        DisposalRowActions,
        DisposalRowStatus,
        DisposalViewDetails,
        CancelDisposal,
        SubmitDisposal
    },
    data() {
        return {
            items: [],
            fields: [{
                    key: "disposalRequestId",
                    label: "Disposal ID",
                    sortable: true,
                },
                {
                    key: "dateCreated",
                    label: "Date Created",
                    sortable: true,
                },
                {
                    key: "assetDetails.assetCode",
                    label: "Asset Code",
                    sortable: true,
                },
                {
                    key: "assetDetails.details.name",
                    label: "Asset Type",
                    sortable: true,
                },
                {
                    key: "createdBy",
                    label: "Maintenance",
                    sortable: true,
                },
                {
                    key: "assetDetails.details.netBookValue",
                    label: "Net Book Value",
                    sortable: true,
                    formatter: (value) => {
                        return value ?
                            value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " PHP" :
                            "-";
                    },
                },
                "status",
                {
                    key: "actions",
                    thClass: "text-center",
                },
            ],
            currentPage: 1,
            perPage: 10,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: "asc",

            filterByOptions: {
                disposalStatusOptions: config.disposalStatusOptions,
                assetTypes: [],
            },

            defaultFilterBy: {
                dateFrom: moment().format("YYYY-MM-DD"),
                dateTo: moment().format("YYYY-MM-DD"),
                status: null,
                assetType: config.assetTypeDefaultValue,
                assetCode: "",
                disposalRequestId: "",
            },
            filterBy: {
                dateFrom: moment().format("YYYY-MM-DD"),
                dateTo: moment().format("YYYY-MM-DD"),
                status: null,
                assetType: "",
                assetCode: "",
                disposalRequestId: "",
            },
            prevFilter: {},
            allDisposalsObj: {},

            approverOptions: [],

            isSuperAdmin: this.$store.getters.isSuperAdmin,
            isViewer: this.$store.getters.isViewer,
            loggedUser: this.$store.getters.loggedUser,
            loggedUserCompany: this.$store.getters.loggedUserCompany,

            // Check for loader
            isLoading: false,
        };
    },
    computed: {
        /**
         * Returns the set of data to be included in the export. For now this just
         * returns the data as is.
         *
         * @returns {Array} the set of data to be included in the export.
         */
        exportData() {
            return this.items;
        },

        /**
         * Derives the field information based from the data table configuration.
         *
         * @returns {object} the fields to be included in the export.
         */
        exportFields() {
            return {
                'Disposal ID': 'disposalRequestId',
                'Date Created': 'dateCreated',
                'Asset Code': 'assetDetails.details.assetCode',
                'Asset Type': 'assetDetails.details.name',
                'Maintenance': 'createdBy',
                'Net Book Value': 'assetDetails.details.netBookValue',
                'Status': 'status',
            };
        },

        fileName() {
            let currTimeStamp = DateUtil.getCurrentTimestamp();
            return "Disposal-" + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
        },
        dateFrom() {
            const dateTo = moment();
            const dateFrom = dateTo.add(-30, "days");
            return dateFrom.format("YYYY-MM-DD");
        },
        dateTo() {
            return moment().format("YYYY-MM-DD");
        },
    },
    mounted() {
        setTimeout(async () => {
            try {
                // Filter Access
                if (this.$store.getters.isScanner) {
                    this.$router.push("/dashboard");
                    this.$toaster.warning("You are not allowed to access this page.");
                }

                const approverUsersObj = _.filter({
                        ...this.$store.getters.users,
                    },
                    (o) => {
                        return o.type === config.approverRole;
                    }
                );

                this.filterByOptions.assetTypes = DropDownItemsUtil.retrieveAssetTypes({
                        ...this.$store.getters.assetTypes,
                    },
                    true
                );
                this.approverOptions = DropDownItemsUtil.retrieveActiveUsers(approverUsersObj);
                this.defaultFilterBy.dateFrom = this.dateFrom;
                this.defaultFilterBy.dateTo = this.dateTo;

                await this.resetFilters();
            } catch (_error) {
                this.$toaster.error("Error loading data. Please reload the page again.");
            } finally {
                // hide loading indicator
                this.isLoading = false;
            }
        }, config.timeout);

        // Event Listeners
        EventBus.$on("onCloseDisposalRequest", (disposalObj) => {
            this.updateTable(disposalObj);
        });
    },
    methods: {
        updateTable(disposalObj) {
            if (_.isEmpty(disposalObj)) {
                return;
            }
            this.allDisposalsObj[disposalObj.id] = disposalObj;

            this.filterDisposals();
        },
        async onFilterRequest() {
            if (!this.validateFilter()) {
                return;
            }

            if (!_.isEqual(this.filterBy, this.prevFilter)) {
                await this.retrieveData();
                this.prevFilter = {
                    ...this.filterBy,
                };
            }
        },
        validateFilter() {
            let isValid = true;

            if (_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo)) {
                this.$toaster.warning("Date From and Date To are required.");
                isValid = false;
            } else if (
                (_.isEmpty(this.filterBy.dateFrom) && !_.isEmpty(this.filterBy.dateTo)) ||
                (!_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo))
            ) {
                this.$toaster.warning(
                    "Invalid Date Range. Date From and Date To must both have value."
                );
                isValid = false;
            } else if (this.filterBy.dateFrom > this.filterBy.dateTo) {
                this.$toaster.warning("Invalid Date Range. Date From must be less than Date To.");
                isValid = false;
            } else if (
                DateUtil.getNoOfDays(this.filterBy.dateFrom, this.filterBy.dateTo) > 90
            ) {
                this.$toaster.warning(
                    "Invalid Date Range. Data range is allowed up to 90 days difference."
                );
                isValid = false;
            } else if (!this.isValidAssetCode(this.filterBy.assetCode)) {
                this.$toaster.warning(
                    `Invalid Asset Code. "${this.filterBy.assetCode}" doesn't follow any of your asset tagging format.`
                );
                isValid = false;
            } else if (!ValidationUtil.isAlphaNumeric(this.filterBy.disposalRequestId)) {
                this.$toaster.warning("Invalid disposal ID. Please enter a valid disposal ID");
                isValid = false;
            }

            return isValid;
        },
        dateFromDisabled(_ymd, date) {
            return date > new Date();
        },
        showFormattedDateTime(date) {
            return DateUtil.getFormattedDateWithTime(date);
        },
        isValidAssetCode(assetCode) {
            return (
                _.isEmpty(assetCode) ||
                ValidationUtil.isValidAssetCode(this.allAssetTypesObj, assetCode)
            );
        },
        async resetFilters() {
            if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
                // reset to default
                this.filterBy = {
                    ...this.defaultFilterBy,
                };
                this.prevFilter = {
                    ...this.filterBy,
                };

                // reset validation
                this.$validator.reset();
                this.errors.clear();

                await this.retrieveData();
            }
        },
        async retrieveData() {
            try {
                // show loading indicator
                this.isLoading = true;

                let param = this.getParam();

                const {
                    data
                } = await disposalRequestApi.getDisposalRequest(param);
                this.allDisposalsObj = data.disposalRequests;

                this.filterDisposals();
            } catch (_error) {
                this.$toaster.error("Error loading data. Please reload the page again.");
            } finally {
                // hide loading indicator
                this.isLoading = false;
            }
        },
        filterDisposals() {
            let filteredObjs = {
                ...this.allDisposalsObj,
            };

            let fromTimestamp = DateUtil.startDateTimeStamp(new Date(this.filterBy.dateFrom));
            let toTimestamp = DateUtil.endDateTimeStamp(new Date(this.filterBy.dateTo));

            _.forEach(filteredObjs, (disposal, id) => {
                if (disposal.dateCreated < fromTimestamp && disposal.dateCreated > toTimestamp) {
                    delete filteredObjs[id];
                }

                let status = this.filterBy.status;
                if (status && status.length > 0 && status !== disposal.status) {
                    delete filteredObjs[id];
                }

                let assetCode = this.filterBy.assetCode;
                if (
                    assetCode &&
                    assetCode.length > 0 &&
                    assetCode !== disposal.assetDetails.assetCode
                ) {
                    delete filteredObjs[id];
                }

                let assetType = this.filterBy.assetType;
                if (
                    assetType &&
                    assetType.length > 0 &&
                    assetType.name != disposal.assetDetails.name
                ) {
                    delete filteredObjs[id];
                }

                let disposalId = this.filterBy.disposalId;
                if (
                    disposalId &&
                    disposalId.length > 0 &&
                    disposalId !== disposal.disposalRequestId
                ) {
                    delete filteredObjs[id];
                }
            });

            this.processDisposals(filteredObjs);
        },
        processDisposals(disposals) {
            this.items = Object.values(disposals);
            this.items = _.sortBy(this.items, ["dateCreated"]);
            this.items.reverse();

            this.$store.dispatch("setAllDisposals", disposals);

            // refresh table
            if (this.$refs.disposalTable) {
                this.$refs.disposalTable.refresh();
            }
        },
        getFormattedDateWithTime(date) {
            return DateUtil.getFormattedDateWithTime(date);
        },
        getParam() {
            let filter = {
                ...this.filterBy,
            };
            filter.fromTimestamp = DateUtil.startDateTimeStamp(new Date(filter.dateFrom));
            filter.toTimestamp = DateUtil.endDateTimeStamp(new Date(filter.dateTo));

            return {
                filterBy: filter,
                currUserId: this.loggedUser.id,
                view: this.isSuperAdmin ? config.view.ADMIN : config.view.COMPANY,
            };
        },
    },
    beforeDestroy() {
        EventBus.$off("onCloseDisposalRequest");
    },
};
</script>
