<template>
<b-modal id="submit-disposal" title="Submit Disposal Request" ref="modal" ok-title="Proceed" ok-variant="danger" cancel-title="Close" @ok="handleOk" @show="onReset" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true" centered>
    <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

    <div class="confirm-message">
        Are you sure you want to submit
        <b class="numFont">{{ selDisposal.disposalRequestId }}</b>?
    </div>
    <b-container v-if="!isEmpty">
        <b-row>
            <b-col cols="4" class="text-md-right" sm="4">
                <label for="assetCode" class="font-weight-bold">Asset Code</label>
            </b-col>
            <b-col cols="8" sm="8">
                {{ selDisposal.assetDetails.assetCode }}
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="4" class="text-md-right" sm="4">
                <label for="assetType" class="font-weight-bold pt-0">Asset Type</label>
            </b-col>
            <b-col cols="8" sm="8">
                {{ selDisposal.assetDetails.details.name }}
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="4" class="text-md-right"  sm="4">
                <label for="remarks" class="font-weight-bold p-0">Remarks</label>
            </b-col>
            <b-col cols="8" sm="8">
                {{ selDisposal.reasonForDisposal }}
            </b-col>
        </b-row>
    </b-container>
</b-modal>
</template>

<script>
//Util
import {
    DateUtil
} from "@/utils/dateutil";

// API
import disposalRequestAPI from "@/api/disposalRequestApi";

// Others
import EventBus from "@/shared/event-bus";
import config from "@/config/env-constants";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import _ from "lodash";

export default {
    name: "submit-disposal",
    components: {
        Loading,
    },
    props: {
        loggedUser: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            selDisposal: {},

            currUserId: this.$store.getters.loggedUser.id,

            // Check for loader
            isLoading: false,
        };
    },
    computed: {
        disableConfirmButtons() {
            return this.isLoading;
        },
        disposalRequestId() {
            return this.selDisposal.disposalRequestId;
        },
        isEmpty() {
            return _.isEmpty(this.selDisposal);
        },
    },
    mounted() {
        EventBus.$on("onUpdateDisposal", (disposal) => {
            this.selDisposal = disposal;
        });
    },
    methods: {
        generateDisposal() {
            let currTimestamp = DateUtil.getCurrentTimestamp();

            return {
                ...this.selDisposal,
                updatedBy: this.loggedUser.firstName + " " + this.loggedUser.lastName,
                dateUpdated: currTimestamp,
                status: config.disposalStatus.NBV_REVIEW
            };
        },
        getParam() {
            let disposal = this.generateDisposal();

            return {
                currUserId: this.currUserId,
                disposal: disposal,
            };
        },
        async handleOk(evt) {
            // Prevent modal from closing
            evt.preventDefault();

            // show loading indicator
            this.isLoading = true;

            await this.submitDisposalRequest();
        },
        async submitDisposalRequest() {
            try {
                let param = this.getParam();
                let {
                    data
                } = await disposalRequestAPI.saveDisposalRequest(param);

                if (data.isSuccess) {
                    this.$toaster.success(data.message);
                    EventBus.$emit("onCloseDisposalRequest", data.disposalRequest);
                    this.$refs.modal.hide();
                } else {
                    this.$toaster.error(
                        `Error submitting Disposal Request "${this.disposalRequestId}". Please try again.`
                    );
                }
            } catch (_error) {
                console.log(_error);
                this.$toaster.error(
                    `Error submitting Disposal Request "${this.disposalRequestId}". Please try again.`
                );
            }

            // hide loading indicator
            this.isLoading = false;
        },
        onReset() {
            // do nothing
        },
    },
    beforeDestroy() {
        EventBus.$off("onUpdateDisposal");
    },
};
</script>
