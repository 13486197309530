<template>
<div>
    <span class="text-nowrap">
        <b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="dark" @click.stop="row.toggleDetails" class="mr-1">
            <i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
            <i class="fa fa-eye" v-else></i>
        </b-button>
        <b-button size="sm" v-b-modal.edit-disposal v-b-tooltip.hover.top="'Edit Details'" @click.stop="updateDisposal(row.item)" v-if="row.item.status!=disposalStatus.APPROVED && row.item.status!=disposalStatus.CANCELLED" variant="warning" class="mr-1">
            <i class="fa fa-pencil"></i>
        </b-button>
        <b-button size="sm" v-b-modal.cancel-disposal v-b-tooltip.hover.top="'Cancel'" variant="danger" class="mr-1" @click.stop="updateDisposal(row.item)" v-if="row.item.status!=disposalStatus.APPROVED && row.item.status!=disposalStatus.CANCELLED">
            <em class="fa fa-ban"></em>
        </b-button>
        <b-button size="sm" v-b-modal.submit-disposal v-b-tooltip.hover.top="'Submit'" variant="success" class="mr-1" @click.stop="updateDisposal(row.item)" v-if="row.item.status==disposalStatus.DRAFT">
            <em class="fa fa-check"></em>
        </b-button>
    </span>
</div>
</template>

  
  
<script>
// Others
import EventBus from "@/shared/event-bus";
import config from "@/config/env-constants";

export default {
    name: 'disposal-row-actions',
    props: {
        row: {
            type: Object,
            required: true,
        },
        isViewer: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            disposalStatus: config.disposalStatus,
        };
    },
    methods: {
        updateDisposal(item) {
            this.$store.commit("SET_CURR_ITEM_DISPOSAL", item);
            EventBus.$emit("onUpdateDisposal", item);
        },
    },
};
</script>
