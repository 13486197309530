// Others
import config from '@/config/env-constants';

function getDefaultDisposalObj() {
    return {
        disposalRequestId: '',
        status: config.disposalStatus.DRAFT,
        assetDetails: {
            details: {},
            assetCode: ''
        },
        reasonForDisposal: '',
        reasonForRejection: '',
        approvedBy: '',
        createdBy: '',
        updatedBy: '',
        cancelledBy: '',
        rejectedBy: '',
        reasonForRejection: '',
        dateCreated: null,
        dateUpdated: null,
        dateCancelled: null,
        dateApproved: null,
        dateRejected: null,
        disposalDate: null,
        approvers: []
    };
}

export const DisposalUtil = {
    getDefaultDisposalObj,
};
